import Popup from "./Popup";
import { color_dict } from "../utils/colors";
import { User, X } from "lucide-react";
import Button from "./Button";


function ProfileAuth({
    close,
    user,
    logout
}) {
    
    return (
        <Popup>
            <X size="30px" style={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                color:color_dict.darkerFontColorWarm
            }}
                onClick={close}
            />


            <div style={{
                color: color_dict.darkerFontColorWarm,
                fontSize: '25px',
                marginBottom: '10px',
            }}>
                Hey there, {user.given_name}
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                // flexWrap:'wrap',
                gap: '15px',

                marginBottom: '10px',
            }}>
                <div style={{
                    backgroundColor: color_dict.ligherPapyrus,

                    width: '40px',
                    height: '40px',
                    borderRadius: '100%',
                    overflow: 'hidden',
                    
                    flexShrink: 0,
                    // flex:'1 0 auto',
                }}>
                    {/* <User size="100%" /> */}

                    <img src={user.picture} style={{
                        height: '100%',
                    }} />
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: color_dict.darkerBackground,
                }}>

                    <div>{user.given_name} {user.family_name}</div>
                    <div style={{fontSize:'12px'}}>{user.email}</div>
                </div>

            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: '5px',
            }}>
                {/* <Button onClick={()=>{}}>Leave Feedback</Button> */}
                <Button onClick={logout}>Log-out</Button>
            </div>

            <div style={{
                fontSize:'10px',
                color:color_dict.darkerFontColorWarm
            }}>
                Contact us at: langapp.pro@gmail.com
            </div>
        </Popup>
    )
}

export default ProfileAuth;