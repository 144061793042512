import { useEffect, useState } from "react";

import Popup from "./Popup";
import Button from "./Button";
import languageFiles from '../utils/languageFiles';

import { color_dict } from "../utils/colors";
import { Dot } from "lucide-react";


function TryItOutExpl({ language, handleStoryChange, close, signIn }) {
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`/data_new/${language}/def.json`);
                const data = await response.json();

                console.log("data: ", data);
                handleStoryChange(data['BEGINNER'][0].id, data['BEGINNER'][0].title, 'BEGINNER', false);
                setIsLoaded(true);
            } catch {
                console.log('issue loading data')
            }
        }

        fetchData();

    }, []);

    return (
        <Popup>

            <img src="/brain.png" style={{
                position: 'absolute',
                height: '140px',
                right: 0,
                bottom: -10,
                opacity: 0.7,
                zIndex: 1,
            }} />


            <div style={{
                fontSize: '30px',
                fontWeight: '900',
                marginBottom: '0px',
                zIndex: 2,
                marginBottom: '30px',

            }}>
                Give Us a Try!
                <div style={{
                    fontSize: '15px',
                    color: color_dict.darkerBackground,
                    marginBottom: '10px',
                }}>
                    Right now you'll see the easiest story in the {language} category.
                </div>

                <div style={{
                    fontSize: '15px',
                    color: color_dict.darkerFontColorWarm,
                    marginBottom: '10px',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',

                    gap: '5px',
                }}>
                    {
                        [
                            "Translate word-by-word",
                            "Click the checkmark button for hints",
                            "Using a hint or making a mistake restarts the sentence!",
                        ].map((item, index) => (
                            <div key={index} style={{
                                // backgroundColor:'blue',
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'flex-start',
                                // alignItems:'center',
                                gap:'10px',
                            }}>
                                <div style={{
                                    backgroundColor:color_dict.darkerFontColorWarm,
                                    flex:'0 0 auto',
                                    height:'5px',
                                    width:'5px',
                                    borderRadius:'100%',
                                    marginTop:'7px',
                                }}/>
                                {item}
                            </div>
                        ))
                    }
                </div>

                {/* <div style={{
                    fontSize: '15px',
                    color: color_dict.darkerBackground,
                    width: '50%',
                }}>
                    Sign-up for free to access more stories!
                </div> */}
            </div>

            <div style={{
                // backgroundColor:'blue',
                height:'70px',

                width: '55%',
                marginBottom: '5px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'stretch',

                gap: '5px',
            }}>
                <Button style={{ alignSelf: 'stretch' }} active={isLoaded} onClick={close}>Understood!</Button>
                {/* <Button style={{ alignSelf: 'stretch' }} active={isLoaded} onClick={signIn}>Sign-Up</Button> */}
            </div>
        </Popup>
    )
}

export default TryItOutExpl;
