import { box_shadows, color_dict } from "../utils/colors";
import Button from "./Button";
import { Cross, X } from "lucide-react";
import Popup from "./Popup";

function Auth({close, login, register}) {
    return (
        <div
            style={{
                backgroundColor: color_dict.papyrus,
                width: '300px',
                height: '250px',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                gap: '10px',
                padding: '10px 15px',
                boxSizing: 'border-box',
                color: color_dict.darkerFontColorWarm,
                fontWeight: '700',
                overflow: 'hidden',

                position: 'relative'
            }}
        >
            <X size="30px" style={{
                position:'absolute',
                right:'10px',
                top:'10px'  
            }}
                onClick={close}
            />


            <img src="/welcome.png" style={{
                position: 'absolute',
                height: '170px',
                right: 0,
                bottom: -20,
                opacity: 0.7,
            }}/>

            <div style={{
                fontSize: '30px',
                fontWeight: '900',
                marginBottom: '0px',
            }}>
                First time?
                <div style={{
                    fontSize:'15px',
                    color:color_dict.darkerBackground
                }}>
                    Join our growing faily of hard-core language learners!
                </div>
            </div>

            <Button style={{
                backgroundColor: color_dict.darkerBackground,
                color: color_dict.ligherPapyrus2,
                fontSize: '20px',

                alignSelf: 'start',
                zIndex: 2,
                boxShadow: box_shadows.A3
            }}
            onClick={register}
            >
                Register
            </Button>

            <Button style={{
                backgroundColor: color_dict.darkerBackground,
                color: color_dict.ligherPapyrus2,
                fontSize: '20px',
                alignSelf: 'start',
                zIndex: 2,
                boxShadow: box_shadows.A3
            }}
            onClick={login}
            >
                Login
            </Button>


        </div>
    )
}

export default Auth;