import { useState } from "react";

import { color_dict } from "../utils/colors";
import languageFlags from "../utils/languageFlags";

import NavBar from "./NavBar";


function HomePage({
    isLoading,
    isAuthenticated,
    user,
    login,
    register,
    logout,
    getToken,
    isPremium,
}) {

    const [currentLanguage, setCurrentLanguage] = useState('Spanish');

    return (
        <div style={{
            backgroundColor: color_dict.mediumBackground,
            width: '100vw',
            height: '100vh',
            fontFamily: 'Plus Jakarta Sans',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            minWidth: 1,
            minHeight: 1,
            overflow: 'hidden',
            position: 'absolute',
            right: 0,
            top: 0,
        }}
        >

            <NavBar
                isAuthenticated={isAuthenticated}
                user={user}
                isPremium={isPremium}
            />

            <div style={{
                fontSize: '40px',
                color: color_dict.ligherPapyrus,

                padding: '15px',
                paddingBottom:'2px',
                boxSizing: 'border-box',
                fontWeight: '700',

            }}>
                Welcome, {user.given_name}
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                padding: '0px 15px',
                boxSizing: 'border-box',
                marginBottom:'10px',
            }}>
                <div style={{

                    fontSize: '20px',

                    flex: '1 0 0',
                    textAlign: 'center',

                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}>
                    <div style={{
                        backgroundColor: color_dict.papyrus,
                        padding: '5px 15px',
                        boxSizing: 'border-box',

                        color: color_dict.darkerFontColorWarm,
                        fontWeight: 700,

                        borderRadius: '20px',

                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap:'5px',

                    }}>
                        <img src={"flag_icons/" + languageFlags[currentLanguage]} style={{
                            height: '30px',
                        }} />
                        Spanish
                    </div>
                </div>
                <div style={{
                    flex: '1 0 0%',
                    
                    textAlign: 'center',
                    fontSize:'20px',
                    color:color_dict.papyrus,

                    
                    alignSelf:'end',
                }}>90% Coverage</div>

            </div>

            <div style={{
                // backgroundColor: 'red',
                height: '220px',

                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
            }}>

                <div style={{
                    // backgroundColor: 'blue',
                    flex: '1 0 0%',

                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'flex-start',
                    alignItems:'stretch',
                    gap:'5px',
                }}>
                    <div style={{
                        alignSelf:'flex-start',
                        padding:'5px 15px',

                        fontSize:'18px',
                        color: color_dict.papyrus
                    }}>
                        Activity
                    </div>
                    
                </div>


                <div style={{
                    backgroundColor: 'green',
                    flex: '1 0 0%',
                }}>
                    
                </div>

            </div>

        </div>
    )
}

export default HomePage;