import { LoaderCircle } from "lucide-react";

function LoadingWheel({})
{
    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform:'translateX(-50%) translateY(-50%)'
        }}>
            <LoaderCircle size="30px" style={{
                animation: 'rotate 2s linear infinite',
            }}>
                <style>{`
@keyframes rotate {
from {
rotate: 0deg;
}
to {
rotate: 360deg;
}
}
`}</style>
            </LoaderCircle>
        </div>
    )
}

export default LoadingWheel;