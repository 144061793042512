import { box_shadows, color_dict } from "../utils/colors";
import Button from "./Button";
import Popup from "./Popup";


function Welcome({ tryItOut, signIn }) {

    return (
        <Popup style={{

        }}>
            <img style={{
                position: 'absolute',
                height: '200px',
                right: -20,
                bottom: 0,
                zIndex: 0,
                opacity: 0.7,
            }}
                src="thinking_girl.png" />
            <div style={{
                fontSize: '24px',
                color: color_dict.darkerBackground,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                gap: '5px',
                whiteSpace: 'nowrap',
                fontWeight: '800',
                marginTop: '10px',
            }}>
                Welcome to, <div style={{
                    backgroundColor: color_dict.darkerFontColorWarm,
                    color: color_dict.ligherPapyrus,
                    padding: '3px 10px 4px 10px',
                    boxSizing: 'border-box',
                    borderRadius: '20px',
                    fontWeight: '400',

                    alignSelf: 'flex-end',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    boxShadow: box_shadows.A1

                }}>langapp.pro</div>
            </div>

            <div style={{
                color: color_dict.darkerFontColorWarm,
                textAlign: 'start',

                fontSize: '17px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

            }}>
                <div>A different, novel approach to language learning.</div>
                <div style={{
                    width: '70%',
                    color: color_dict.darkerBackground,
                    fontSize: '15px',
                    textAlign: 'center',

                    marginTop: '10px',

                    padding: '5px',
                    paddingTop: '10px',

                    boxSizing: 'border-box',
                    gap: '10px',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch'
                }}>
                    {
                        [
                            "Learn a language through stories",
                            "Novel approach to memorization"
                        ].map((item, index) => (
                            <div key={index} style={{
                                backgroundColor: color_dict.lightBlue,
                                opacity: 0.7,
                                padding: '5px',
                                boxSizing: 'border-box',
                                borderRadius: '10px',
                                color: color_dict.fontColor,
                                boxShadow: box_shadows.A3
                            }}>
                                {item}
                            </div>
                        ))
                    }
                </div>


                <div style={{
                    alignSelf: 'stretch',
                    marginTop: '15px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    gap: '10px',
                    marginBottom: '10px',
                    zIndex: 2,
                }}>
                    <Button onClick={tryItOut}>Try it out</Button>
                    <Button onClick={signIn}>Sign-In</Button>
                </div>
            </div>
        </Popup>
    )
}

export default Welcome;