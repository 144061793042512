import React from 'react';
import { color_dict } from '../utils/colors';

function Word({ value, active, translated, translation, reverse, setShouldFocus }) {
  return (
    <div
      style={{
        backgroundColor:
          translated
            ? 'lightgreen'
            : active
              ? color_dict.lightSecondary
              : (reverse ? color_dict.lightBlue : color_dict.ligherPapyrus)
        ,
        borderRadius: '30px',
        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
        fontWeight: '500',
        color: translated
          ? 'darkgreen'
          : active
            ? color_dict.darkerBackground
            : color_dict.fontColor,
        outline: active ? `2px solid ${(reverse ? color_dict.ligherPapyrus : color_dict.darkerBackground)}` : '0px',
        boxShadow: active ? `inset 0 0 0 2px ${(reverse ? color_dict.ligherPapyrus : color_dict.darkerBackground)}` : 'none',
        padding: '5px 10px',
        boxSizing: 'border-box',
        transition: '0.2s all',
        position: 'relative',
        cursor: active ? 'pointer' : 'default'
      }}
      onClick={() => {
        if (active) {
          setShouldFocus(true);
        }
      }}
    >
      <div style={{ transform: `translateY(${translation ? 5 : 0}px)` }}>{value}</div>
      {translation && (
        <div
          style={{
            position: 'absolute',
            top: '-10%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '2px 5px',
            borderRadius: '5px',
            fontSize: '0.8em',
            whiteSpace: 'nowrap',
          }}
        >
          {translation}
        </div>
      )}
    </div>
  );
}

export default Word;
