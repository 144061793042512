import React, { useState, useEffect, useMemo } from 'react';
import languageFiles from '../utils/languageFiles';
import { color_dict, box_shadows } from "../utils/colors";
import Button from './Button';
import languageFlags from '../utils/languageFlags';

import { CheckCheck, CheckCircle, Lock } from 'lucide-react';
import LoadingWheel from './LoadingWheel';

function StoryCard({ title, selected, onClick, storyClickLocked, unlocked = true }) {

    return (
        <div style={{
            backgroundColor: selected ? color_dict.ligherPapyrus2 : color_dict.ligherPapyrus,
            padding: '5px 15px',
            boxSizing: 'border-box',

            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',

            height: '45px',
            boxShadow: box_shadows.A3,

            color: selected ? color_dict.darkerBackground : color_dict.darkerFontColorWarm,

            transform: selected ? 'scale(103%)' : '',
            transition: '0.2s all',

            position: 'relative',

            cursor: unlocked ? 'pointer' : 'default',
            overflow: 'hidden'
        }}
            onClick={() => {
                if (unlocked)
                    onClick()
                else
                    storyClickLocked()
            }}
        >

            <CheckCheck color="green" style={{
                position: 'absolute',
                left: '10px',
                opacity: selected ? 1 : 0,
                transition: '0.2s all'
            }} />

            <div style={{
                fontSize: '16px',
                fontWeight: '900',
                textAlign: 'center',
            }}>
                {title}
            </div>

            {

                !unlocked && (
                    <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(224,209,182, 0.5)',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,

                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Lock size='30px' style={{ color: color_dict.darkerFontColor }} />
                    </div>
                )
            }
        </div>
    )
}

function formatString(input) {
    return input
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}


function StoryCardV({
    language,
    id,
    title,
    description,
    tags,
    selected,
    onClick,
    storyClickLocked,
    unlocked = true
}) {

    const parsedTags = useMemo(() => (
        tags
            .map((item) => formatString(item))
            .sort((a, b) => b.length - a.length) // Sort by length
    ), [tags]);



    return (
        <div style={{
            backgroundColor: selected ? color_dict.ligherPapyrus2 : color_dict.ligherPapyrus,
            flexShrink: 0,
            width: '140px',
            height: '140px',

            padding: '5px',
            boxSizing: 'border-box',
            borderRadius: '10px',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            boxShadow: box_shadows.A3,

            outline: `${selected ? '3px' : '0px'} solid ${color_dict.lightBlue}`,

            transform: `scale(${(selected ? '1.05' : '1')})`,

            overflow: 'hidden',
            transition: '0.2s all',

            cursor: unlocked ? 'pointer' : 'default',
            position: 'relative'
        }}
            onClick={() => {
                if (unlocked)
                    onClick()
                else
                    storyClickLocked()
            }}
        >
            <CheckCircle style={{
                opacity: selected ? 1 : 0,
                position: 'absolute',
                right: '5px',
                bottom: '5px',

                transition: '0.2s all'
            }} />
            <div style={{
                color: color_dict.darkerFontColor,
                fontSize: '13px',
            }}>
                {title}
            </div>


            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexWrap: 'wrap',
                rowGap: '3px',
                columnGap: '5px',
            }}>
                {
                    parsedTags.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: color_dict.lightBlue,
                                padding: '2px 6px',
                                borderRadius: '5px',
                                fontSize: '8px',
                            }}>
                            {item}
                        </div>
                    ))
                }
            </div>

            <div style={{
                color: color_dict.fontColor,
                fontSize: '10px',
                fontWeight: '500',
            }}>
                {description}
            </div>

            {

                !unlocked && (
                    <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(224,209,182, 0.5)',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,

                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Lock size='30px' style={{ color: color_dict.darkerFontColor }} />
                    </div>
                )
            }

        </div>
    )
}

function StoriesCategory({
    language,
    category,
    stories,
    selectedID,
    setSelectedID,
    setSelectedStoryTitle,
    setSelectedStoryLevel,
    storyClickLocked,
    isPremium
}) {


    return (
        <div style={{
            // backgroundColor:'red',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',

            gap: '5px',

            overflowY: 'visible',
        }}>
            <div style={{
                color: color_dict.darkerFontColorWarm,
            }}>
                {category}
            </div>

            <div style={{
                // backgroundColor: 'green',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                gap: '10px',
                overflowX: 'auto',
                overflowY: 'visible',

                padding: '15px 5px',
                boxSizing: 'border-box',

            }}>
                {
                    stories.map((item, index) => (
                        <StoryCardV
                            key={index}
                            language={language}
                            title={item.title}
                            description={item.description}
                            tags={item.tags}
                            id={item.id}
                            selected={selectedID == item.id}
                            onClick={() => {
                                setSelectedID(item.id);
                                setSelectedStoryTitle(item.title);
                                setSelectedStoryLevel(category);
                            }
                            }

                            storyClickLocked={storyClickLocked}
                            unlocked={(category === 'BEGINNER' && index < 4) || (category == 'INTERMEDIATE' && index < 1) || isPremium}
                        />

                    ))
                }
            </div>
        </div>
    )
}


function PickStory({
    language,
    handleStoryChange,
    storyClickLocked,
    changeLanguage,
    isAuthenticated,
    isPremium = false
}) {


    const [selectedStoryTitle, setSelectedStoryTitle] = useState("");
    const [selectedStoryLevel, setSelectedStoryLevel] = useState("");
    const [selectedStoryID, setSelectedStoryID] = useState(null);

    const [selectedID, setSelectedID] = useState(null);
    const [storiesSchema, setStoriesSchema] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`/data_new/${language}/def.json`);
                const data = await response.json();

                console.log("data: ", data);
                setStoriesSchema(data);
            } catch {
                console.log('issue loading data')
            }
        }

        fetchData();

    }, []);

    return (
        <div
            style={{
                backgroundColor: color_dict.darkerBackground,
                width: '300px',
                height: '500px',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                gap: '12px',
                padding: '12px 15px',
                boxSizing: 'border-box',
                color: color_dict.darkerFontColor,
                fontWeight: '700',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: '300',
                    color: color_dict.ligherPapyrus,

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',


                }}
            >
                Pick a <span style={{ fontWeight: '900' }}>Story</span> in{' '}
                <div style={{
                    // backgroundColor:color_dict.ligherPapyrus,
                    fontWeight: '900',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '5px',
                }}>
                    <img src={'/flag_icons/' + languageFlags[language]} style={{
                        height: '25px',
                    }} />

                    {language}
                </div>
            </div>

            <div
                style={{
                    backgroundColor: color_dict.papyrus,
                    flex: '1 0 0%',

                    borderRadius: '10px',

                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',

                    gap: '15px',

                    padding: '10px',
                    boxSizing: 'border-box',

                    overflowY: 'auto',
                    position: 'relative'
                }}
            // onClick={()=>setSelectedID(null)}
            >
                {/* You can now use storiesData to render the stories */}
                {
                    storiesSchema ?
                        (
                            Object.keys(storiesSchema).map((item, index) => (
                                <StoriesCategory key={index}
                                    language={language}
                                    category={item}
                                    stories={storiesSchema[item]}
                                    selectedID={selectedID}
                                    setSelectedID={setSelectedID}
                                    setSelectedStoryTitle={setSelectedStoryTitle}
                                    setSelectedStoryLevel={setSelectedStoryLevel}
                                    storyClickLocked={storyClickLocked}
                                    isPremium={isPremium}
                                />
                            ))
                        )
                        :
                        <LoadingWheel />
                }
            </div>


            <div style={{
                alignSelf: 'center',

                width: '80%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
            }}>
                <Button active={selectedID !== null} style={{
                    fontSize: '20px',
                    alignSelf: 'stretch',
                }} onClick={() => {
                    handleStoryChange(selectedID, selectedStoryTitle, selectedStoryLevel);
                }
                }>Start</Button>

                <Button style={{
                    fontSize: '20px',
                    alignSelf: 'stretch',
                }}
                    onClick={changeLanguage}
                >
                    Change Language
                </Button>
            </div>
        </div>
    );
}

export default PickStory;
