
import { color_dict, box_shadows } from '../utils/colors';
import Button from './Button';
import Popup from './Popup';

function FinishedStory({ attempts, isPremium, onClick, isAuthenticated, signIn, pickStory, payWall }) {
    return (
        <Popup>
            <img src="happy_guy.png" style={{
                position: 'absolute',
                opacity: 0.6,
                right: 0,
                bottom: -10,

                height: '150px',
                zIndex: 1
            }} />
            <div
                style={{
                    fontSize: '30px',
                    zIndex: 2
                }}
            >
                Good Job!
            </div>

            <div style={{
                zIndex: 2,
                backgroundColor: color_dict.ligherPapyrus,
                padding: '5px 10px',
                boxSizing: 'border-box',
                borderRadius: '10px',
                boxShadow: box_shadows.A3,
                color: color_dict.fontColor,
                alignSelf: 'flex-start',

            }}>
                You finished the story
                <br />
                It took you {attempts} tries
                <br />
            </div>

            <div style={{
                width: '50%',
                fontSize: '12px',
                whiteSpace: 'wrap',
                marginBottom: '20px',
                color: color_dict.darkerBackground
            }}>To learn the story keep practicing until it takes you just 1 try</div>

            <Button style={{
                zIndex: 2,
            }}
                onClick={ isPremium ? pickStory : payWall}
            >
                Next Story
            </Button>

        </Popup>
    )
}

export default FinishedStory;