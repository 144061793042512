import React from 'react';
import Word from './Word';
import { color_dict } from '../utils/colors';
import { ArrowRight } from 'lucide-react';

function Sentence({
  sentence,
  selectedWordIndex,
  translations,
  reverseTranslations,
  sentenceIndex,
  currentSentenceIndex,
  phonetic,
  reverse,
  language,
  setShouldFocus
}) {
  return (
    <div style={{
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      gap: '10px'
    }}>

      <div style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-start',
        alignItems:'start',
        
      }}>
        <div style={{
          color: reverse ? color_dict.ligherPapyrus : color_dict.darkerFontColorWarm,
          fontSize:'14px',
          fontWeight:'900',
        }}>Sentence {sentenceIndex}</div>
        <div style={{
          color: reverse ? color_dict.papyrus : color_dict.darkerBackground,
          fontWeight: '600',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '5px',
        }}>

          {
            (currentSentenceIndex === sentenceIndex) && (
              (reverse) ?
                (
                  <>English <ArrowRight size="12px" /> {language}</>
                )
                :
                (
                  <>{language} <ArrowRight size="12px" /> English</>
                )
            )
          }
        </div>
      </div>
      <div
        style={{
          // backgroundColor: reverse ? color_dict.darkerBackground : 'transparent', 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '5px',

          padding: '0px 5px',
          boxSizing: 'border-box',

        }}
      >
        {sentence.words.map((word, index) => (
          (reverse) ?
            (

              <Word
                key={index + '_reversed'}
                value={word.translations[0]}
                active={sentenceIndex === currentSentenceIndex && index === selectedWordIndex}
                translated={reverseTranslations[sentenceIndex] && reverseTranslations[sentenceIndex].hasOwnProperty(index)}
                translation={reverseTranslations[sentenceIndex] && reverseTranslations[sentenceIndex][index]}
                reverse={true}
                setShouldFocus={setShouldFocus}
              />

            )
            :
            (

              <Word
                key={index}
                value={phonetic ? word.phonetic : word.original}
                active={sentenceIndex === currentSentenceIndex && index === selectedWordIndex}
                translated={translations[sentenceIndex] && translations[sentenceIndex].hasOwnProperty(index)}
                translation={translations[sentenceIndex] && translations[sentenceIndex][index]}
                reverse={false}
                setShouldFocus={setShouldFocus}
              />

            )
        ))}
      </div>
    </div>
  );
}

export default Sentence;
