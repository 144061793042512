import React, { useState } from 'react';
import Input from './Input';
import Button from './Button';
import { color_dict, box_shadows } from '../utils/colors';
import { BACKEND_URL } from '../utils/constants';
import { FlagTriangleRight } from 'lucide-react';

function SentenceCheck({
  currentSentence,
  language,
  exampleTranslation,
  wrongTranslation,
  correctTranslation,
  setPopup,
  setRetries
}) {
  const [thinking, setThinking] = useState(false);
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState('');
  const [notes, setNotes] = useState([]);
  const [attempts, setAttempts] = useState(0);

  const judgeSentence = async (translation) => {
    setThinking(true);

    const requestData = {
      english_sentence: currentSentence,
      example_translation: exampleTranslation,
      translation: translation,
      language: language,
    };

    try {
      const response = await fetch(`${BACKEND_URL}/verify_sentence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      const result = await response.json();
      console.log('result: ', result)
      setScore(result.score);
      setNotes(result.notes_for_improvement);
      setAttempts((a) => a + 1);

      if (result.score < 80) {
        setRetries((r) => (r + 1))
      }

    } catch (error) {
      console.error('Error verifying translation:', error);
    }

    setThinking(false);
  };

  return (
    <div
      style={{
        backgroundColor: color_dict.darkerBackground,
        width: '300px',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: '10px',
        padding: '10px 15px',
        boxSizing: 'border-box',
        color: color_dict.ligherPapyrus,
        fontWeight: '700',
      }}
    >
      <div
        style={{
          fontSize: '20px',
          fontWeight: '900',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <FlagTriangleRight size="25px" />
        Sentence Checkpoint!
      </div>

      <div
        style={{
          color: color_dict.papyrus,
          fontWeight: '400',
        }}
      >
        To pass the checkpoint, translate the sentence back to {language}.
      </div>

      <div
        style={{
          backgroundColor: color_dict.papyrus,
          padding: '15px',
          boxSizing: 'border-box',
          color: color_dict.darkerFontColor,
          fontSize: '15px',
          fontWeight: '500',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <div
          style={{
            fontSize: '12px',
            fontWeight: '900',
            alignSelf: 'start',
            color: color_dict.darkerFontColorWarm
          }}
        >
          {3 - attempts} Attempts Left
        </div>
        <div style={{
          backgroundColor: color_dict.ligherPapyrus,
          padding: '5px 10px',
          boxSizing: 'border-box',
          borderRadius: '10px',
          textAlign: 'center',
          boxShadow: box_shadows.A3,
          color: color_dict.fontColor
        }}>
          {currentSentence}
        </div>

        <div
          style={{
            width: '100%',
            color: color_dict.darkerFontColorWarm,
          }}
        >

          <div
            style={{
              fontSize: '12px',
              fontWeight: '900',
            }}
          >
            Score: {score}
          </div>

          <div
            style={{
              width: '100%',
              height: '10px',
              border: `solid 3px ${color_dict.darkerBackground}`,
              borderRadius: '30px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                backgroundColor: color_dict.lightSecondary,
                height: '100%',
                width: `100%`,
                transform: `translateX(-${100 - score}%)`,
                transition: '0.5s all',
              }}
            />
          </div>
        </div>

        {notes.length > 0 && (
          <div
            style={{
              color: color_dict.darkerFontColor,
              maxHeight: '100px',
              overflowY: 'auto',

              padding: '5px',
              boxSizing: 'border-box',

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              gap: '10px',
            }}
          >
            {
              notes.map((item, index) => (
                <div style={{

                  backgroundColor: color_dict.lightBlue,
                  fontSize: '12px',
                  fontWeight: '500',
                  padding: '5px',
                  boxSizing: 'border-box',

                  borderRadius: '10px',
                  boxShadow: box_shadows.A3

                }}>
                  {item}
                </div>
              ))
            }
          </div>
        )}
      </div>

      <div style={{
        display: 'flex',
        flexDirection: (score < 80 && attempts < 3) ? 'column-reverse': 'row',
        justifyContent: 'space-around',
        gap: (score < 80 && attempts < 3) ? '10px' : '0px',
        alignItems:'center'
      }}>
        {
          (score > 80 || attempts < 3) && (
            <Button
              style={{
                whiteSpace:'nowrap',
                backgroundColor: color_dict.lightRed,
                color: color_dict.darkRed
              }}
              onClick={() => {
                wrongTranslation();
                setPopup(false);
              }}
              active={!thinking}
            >
              Give up
            </Button>
          )
        }

        {score >= 80 ? (
          <Button
            onClick={() => {
              setPopup(false);
              correctTranslation();
            }}
          >
            Proceed
          </Button>
        ) : attempts >= 3 ? (
          <Button
            onClick={() => {
              wrongTranslation();
              setPopup(false);
            }}
          >
            Close
          </Button>
        ) : message.length > 0 ? (
          <Button onClick={() => setMessage('')}>Try again</Button>
        ) : (
          <Input
            style={{
              width: '100%',
              fontSize: '15px',
              height: '60px',
              marginLeft: '10px',
            }}
            checkTranslation={judgeSentence}
            thinking={thinking}
            popup={false}
          />
        )}


      </div>

    </div>
  );
}

export default SentenceCheck;
