const languageFlags = {
    Spanish: 'es.png',
    German: 'de.png',
    Italian: 'it.png',
    French: 'fr.png',
    Korean: 'kr.png',
    Chinese: 'cn.png',
    Japanese: 'jp.png',
    Russian: 'ru.png',
    Lithuanian: 'jp.png'
}

export default languageFlags;