import React from 'react';
import IncorrectPopup from './IncorrectPopup';
import SentenceCheck from './SentenceCheck';
import LanguageChoice from './LanguageChoice';
import PickStory from './PickStory';
import FinishedStory from './FinishedStory';
import TryItOutExpl from './TryItOutExpl';
import Paywall from './Paywall';
import Instruction from './Instruction';

import Auth from './Auth';
import ProfileAuth from './ProfileAuth';
import Welcome from './Welcome';

function PopupManager({
  setPopupState,
  popupState,
  popup,
  setPopup,
  hint,
  setHint,
  viewportHeight,
  language,
  data,
  currentSentenceIndex,
  selectedWordIndex,
  resetCurrentSentence,
  goToNextSentence,
  phonetic,
  currentTranslation,
  handleLanguageChange,
  handleStoryChange,
  attempts,
  resetGame,
  setAttempts,
  reverse,
  setLevel,
  login,
  register,
  logout,
  user,
  isAuthenticated,
  isLoading,
  isPremium
}) {
  return (
    <>
      {(popup || isLoading) && (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            height: viewportHeight,
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {popupState === 'incorrect' && (
            <IncorrectPopup
              currentWord={data[currentSentenceIndex].words[selectedWordIndex]}
              selectedWordIndex={selectedWordIndex}
              currentSentence={data[currentSentenceIndex].words}
              setPopup={setPopup}
              hint={hint}
              setHint={setHint}
              resetCurrentSentence={resetCurrentSentence}
              language={language}
              phonetic={phonetic}
              currentTranslation={currentTranslation}
              reverse={reverse}
            />
          )}

          {
            popupState === 'reverse_sentence_instruction' && (
              <Instruction
                language={language}
                title="Good Job!"
                close={() => setPopup(false)}
                sentence={data[currentSentenceIndex]}
              >

              </Instruction>
            )
          }

          {
            popupState === 'language_choice' && (
              <LanguageChoice
                handleLanguageChange={handleLanguageChange}
              />
            )
          }

          {
            popupState === 'paywall' && (
              <Paywall
                close={() => setPopupState("pick_story")}
                isAuthenticated={isAuthenticated}
                isPremium={isPremium}
              />
            )
          }

          {
            popupState === 'try_it_out_expl' && (
              <TryItOutExpl
                language={language}
                handleStoryChange={handleStoryChange}
                close={() => {
                  if (data)
                    setPopup(false);
                  else
                    setPopupState("language_choice");
                }}
                signIn={() => {
                  setPopup(true);
                  setPopupState("auth");
                }}
              />
            )
          }

          {popupState === 'auth' && (
            <Auth
              login={login}
              register={register}
              close={() => {
                if (data)
                  setPopup(false);
                else
                  setPopupState("language_choice");
              }}
            />
          )
          }

          {popupState === 'profile_auth' && (
            <ProfileAuth
              user={user}
              logout={logout}
              close={() => setPopup(false)} />
          )
          }

          {popupState === 'pick_story' && (
            <PickStory
              isPremium={isPremium}
              isAuthenticated={isAuthenticated}
              language={language}
              changeLanguage={
                () => {
                  setPopup('true')
                  setPopupState('language_choice')
                }
              }
              handleStoryChange={handleStoryChange}
              storyClickLocked={() => {
                setPopup(true);
                setPopupState('paywall');
              }}
            />
          )
          }

          {
            popupState === 'welcome' && (
              <Welcome
                tryItOut={() => {
                  setPopup(true);
                  setPopupState("language_choice");
                }}
                signIn={() => {
                  setPopup(true);
                  setPopupState("auth");
                }}
              />
            )
          }

          {data && popupState === 'sentence_check' && (
            <SentenceCheck
              language={language + (phonetic ? "_PHONETIC" : "")}
              currentSentence={data[currentSentenceIndex].translation}
              exampleTranslation={data[currentSentenceIndex].words.map(word => (phonetic ? word.phonetic : word.original)).join(' ')}
              wrongTranslation={resetCurrentSentence}
              correctTranslation={goToNextSentence}
              setPopup={setPopup}
              setRetries={setAttempts}
            />
          )}

          {data && popupState === 'finished' && (
            <FinishedStory
              isPremium={isPremium}
              attempts={attempts}
              onClick={resetGame}
              isAuthenticated={isAuthenticated}
              signIn={() => {
                setPopup(true);
                setPopupState("auth");
              }}
              pickStory = {()=> {
                setPopup(true);
                setPopupState("pick_story");
              }}
              payWall = {()=> {
                setPopup(true);
                setPopupState("paywall");
              }}
            />
          )}

        </div>
      )}
    </>
  );
}

export default PopupManager;
