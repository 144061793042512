import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import PageRouter from "./PageRouter";

function App() {
  return (
    <KindeProvider clientId="6ec1260be10e405bbabdc09c23c6d6d9"
      domain="https://auth.langapp.pro"
      // redirectUri="http://localhost:3000"
      // logoutUri="http://localhost:3000"
      
      redirectUri="https://www.langapp.pro"
      logoutUri="https://www.langapp.pro"
    >

      <PageRouter/>
  </KindeProvider>
  )
}

export default App;
