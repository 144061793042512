import React from 'react';
import { box_shadows, color_dict } from '../utils/colors';

function Button({ onClick, children, style, active = true }) {

    return (
        <div
            style={{
                opacity: active ? 1 : 0.5,
                backgroundColor: 'rgb(195,228,222)',
                height: '35px',
                alignSelf: 'center',
                padding: '5px 10px',
                boxSizing: 'border-box',
                borderRadius: '30px',
                color: color_dict.darkerFontColor,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
                fontWeight: '600',
                cursor: active ? 'pointer' : 'default',
                boxShadow:box_shadows.A3,
                ...style
            }}
            onMouseDown={(e) => {
                if (active)
                    onClick(e)
            }}
        >
            {children}
        </div>
    );
}

export default Button;
