import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ArrowDownLeftSquare } from "lucide-react"
import { useState, useEffect } from "react";

import { BACKEND_URL } from "./utils/constants";
import MainPage from "./components/MainPage";
import LoadingWheel from "./components/LoadingWheel";
import HomePage from "./components/HomePage";

function PageRouter({ }) {
    const [isLoadingGlobal, setIsLoadingGlobal] = useState(true);
    const [currentPage, setCurrentPage] = useState('loading');

    const { isLoading, isAuthenticated, user, login, register, logout, getToken } = useKindeAuth();
    const [isPremium, setIsPremium] = useState(false);
    const [hasIsPremiumSet, setHasPremiumSet] = useState(false);



    useEffect(() => {
        console.log('use_effect_entry_point', isLoading, isAuthenticated, user, hasIsPremiumSet)
        if (!isLoading && isAuthenticated && user && !hasIsPremiumSet) {

            const url = `${BACKEND_URL}/is_premium?email=${encodeURIComponent(user.email)}`;

            console.log('accessing url: ', url)

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    console.log('is_premium data: ', data)
                    return data.is_premium;
                })
                .catch(error => {
                    console.error('Error checking premium status:', error);
                    // Handle error appropriately
                    return false;
                }).then(
                    (status) => {
                        setIsPremium(status)
                        setHasPremiumSet(true);
                    }

                )

        }
    }, [isLoading, isAuthenticated, user])


    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            setIsLoadingGlobal(false);
            setCurrentPage('main_page');
        }
        else if(!isLoading && isAuthenticated && hasIsPremiumSet) {
            setIsLoadingGlobal(false);
            setCurrentPage('main_page');
        }

    }, [isLoading, isAuthenticated, user, hasIsPremiumSet]);


    return (
        <>
            {

                (
                    (isLoadingGlobal) && (
                        <div>
                            <LoadingWheel />
                        </div>
                    )
                )
            }
            {
                (!isLoadingGlobal && currentPage == 'main_page') && (
                    <MainPage
                        isLoading={isLoading}
                        isAuthenticated={isAuthenticated}
                        user={user}
                        login={login}
                        register={register}
                        logout={logout}
                        getToken={getToken}
                        isPremium={isPremium}
                    />
                )
            }
            {
                (!isLoadingGlobal && currentPage == 'home_page') && (
                    <HomePage
                        isLoading={isLoading}
                        isAuthenticated={isAuthenticated}
                        user={user}
                        login={login}
                        register={register}
                        logout={logout}
                        getToken={getToken}
                        isPremium={isPremium}
                    />
                )
            }
        </>
    )
}

export default PageRouter;