const color_dict = {
    background: 'rgb(196, 177, 158)',
    mediumBackground: 'rgb(178, 154, 130)',
    darkerBackground: 'rgb(160,132,103)',
    papyrus: 'rgb(224,209,182)',
    ligherPapyrus: 'rgb(255,240,218)',
    ligherPapyrus2: 'rgb(255, 247, 235)',
    fontColor: 'rgb(28, 111, 120)',
    darkerFontColor: 'rgb(0, 65, 90)',
    darkerFontColorWarm: 'rgb(130, 101, 70)',
    lightSecondary: 'rgb(255, 228, 152)',
    lightRed: 'rgb(243, 201, 176)',
    darkRed: 'rgb(130, 58, 38)',
    lightBlue: 'rgb(193, 225, 212)',
};

const box_shadows = {
    A1: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    A2: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    A3: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
}


export {color_dict, box_shadows}
