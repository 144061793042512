import { color_dict } from "../utils/colors";


function Popup({children, style})
{
    return (
        <div
            style={{
                backgroundColor: color_dict.papyrus,
                width: '300px',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                gap: '10px',
                padding: '5px 15px',
                boxSizing: 'border-box',
                color: color_dict.darkerFontColor,
                fontWeight: '700',
                overflow: 'hidden',

                position: 'relative',
                ...style
            }}
        >
        {children}
        </div>
    )
}

export default Popup;