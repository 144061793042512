import Popup from "./Popup";
import Button from "./Button";
import { box_shadows, color_dict } from "../utils/colors";
import { X } from "lucide-react";

function PremiumButton({ price, time_period, link }) {
    return (
        <Button style={{
            alignSelf: 'stretch',

            justifyContent: 'space-between',

            paddingLeft: '20px',
            paddingRight: '20px',
        }}
            onClick={() => { window.location.href = link }}
        >
            <div style={{
                backgroundColor: 'rgba(255,255,255,0.6)',
                padding: '2px 4px',
                boxSizing: 'border-box',
                borderRadius: '5px',
            }}>{price}$</div>
            <div>
                <span style={{
                    fontSize: '11px',
                }}>per </span>{time_period}
            </div>
        </Button>
    )
}


function Paywall({ close, isAuthenticated, isPremium }) {
    return (
        <Popup>
            <div style={{
                fontSize: '30px',
                fontWeight: '900',
                marginBottom: '0px',
                zIndex: 2,
                marginBottom: '30px',

            }}>

                {
                    (isAuthenticated) && (
                        <X size="30px" style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px'
                        }}
                            onClick={close}
                        />
                    )
                }


                <img src="/premium.png" style={{
                    position: 'absolute',
                    height: '140px',
                    right: 0,
                    bottom: -10,
                    opacity: 0.7,
                    zIndex: 1,
                }} />


                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    marginBottom: '20px',
                }}>
                    <div style={{
                        fontWeight: '600',
                    }}>
                        Ready to go..
                    </div>

                    <div style={{
                        alignSelf: 'end'
                    }}>
                        Premium?
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',

                    gap: '7px'
                }}>
                    {
                        [
                            "20 stories per language of all topics and difficulties",
                            "Learn basic speaking of any language within days!"
                        ].map((item, index) => (
                            <div key={index} style={{
                                backgroundColor: color_dict.ligherPapyrus,

                                color: color_dict.fontColor,
                                fontSize: '15px',

                                padding: '5px',
                                boxSizing: 'border-box',
                                borderRadius: '7px',

                                fontWeight: 600,

                                boxShadow: box_shadows.A3
                            }}>
                                {item}
                            </div>
                        ))
                    }
                </div>

                {/* <div style={{

                    fontSize: '15px',
                    color: color_dict.darkerFontColorWarm,
                    marginBottom: '10px',

                }}>
                    Get access to all stories in all languages.
                </div>

                <div style={{
                    fontSize: '15px',
                    color: color_dict.darkerBackground,
                    // width: '62%',
                    marginBottom: '10px',
                }}>
                    All future features will be availabe.
                    (there are lots coming soon)
                </div> */}

                {/* <div style={{
                    fontSize: '15px',
                    color: color_dict.darkerFontColorWarm,
                    // width: '62%',
                }}>
                    Choose a payment plan below, cancel at any time.
                </div> */}
            </div>

            <div style={{

                width: '60%',
                marginBottom: '5px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                gap: '10px',
                position: 'relative',
            }}>
                <div style={{
                    width: '100px',

                    textAlign: 'center',

                    fontSize: '13px',
                    position: 'absolute',

                    top: '-23px',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}>Choose a plan</div>

                <PremiumButton
                    price={3}
                    time_period="Month"
                    link='https://langapp.gumroad.com/l/langapp-monthly'
                />
                <div style={{
                    fontSize: '13px',
                    position: 'absolute',

                    bottom: '50%',
                    left: '-7px',
                    transform: 'translateY(50%)'
                }}>or</div>
                <PremiumButton
                    price={25}
                    time_period="Year"
                    link='https://langapp.gumroad.com/l/langapp-yearly'
                />

            </div>
        </Popup>
    )
}

export default Paywall;