const languageFiles = {
  Chinese: {
    BEGINNER: {
      A: 'data/sentences_ch_BEGINNER_A.json',
      B: 'data/sentences_ch_BEGINNER_B.json',
      C: 'data/sentences_ch_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_ch_INTERMEDIATE_A.json',
      B: 'data/sentences_ch_INTERMEDIATE_B.json',
      C: 'data/sentences_ch_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_ch_ADVANCED_A.json',
      B: 'data/sentences_ch_ADVANCED_B.json',
      C: 'data/sentences_ch_ADVANCED_C.json',
    },
  },
  French: {
    BEGINNER: {
      A: 'data/sentences_fr_BEGINNER_A.json',
      B: 'data/sentences_fr_BEGINNER_B.json',
      C: 'data/sentences_fr_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_fr_INTERMEDIATE_A.json',
      B: 'data/sentences_fr_INTERMEDIATE_B.json',
      C: 'data/sentences_fr_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_fr_ADVANCED_A.json',
      B: 'data/sentences_fr_ADVANCED_B.json',
      C: 'data/sentences_fr_ADVANCED_C.json',
    },
  },
  Spanish: {
    BEGINNER: {
      A: 'data/sentences_sp_BEGINNER_A.json',
      B: 'data/sentences_sp_BEGINNER_B.json',
      C: 'data/sentences_sp_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_sp_INTERMEDIATE_A.json',
      B: 'data/sentences_sp_INTERMEDIATE_B.json',
      C: 'data/sentences_sp_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_sp_ADVANCED_A.json',
      B: 'data/sentences_sp_ADVANCED_B.json',
      C: 'data/sentences_sp_ADVANCED_C.json',
    },
  },
  Italian: {
    BEGINNER: {
      A: 'data/sentences_it_BEGINNER_A.json',
      B: 'data/sentences_it_BEGINNER_B.json',
      C: 'data/sentences_it_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_it_INTERMEDIATE_A.json',
      B: 'data/sentences_it_INTERMEDIATE_B.json',
      C: 'data/sentences_it_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_it_ADVANCED_A.json',
      B: 'data/sentences_it_ADVANCED_B.json',
      C: 'data/sentences_it_ADVANCED_C.json',
    },
  },
  Korean: {
    BEGINNER: {
      A: 'data/sentences_kr_BEGINNER_A.json',
      B: 'data/sentences_kr_BEGINNER_B.json',
      C: 'data/sentences_kr_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_kr_INTERMEDIATE_A.json',
      B: 'data/sentences_kr_INTERMEDIATE_B.json',
      C: 'data/sentences_kr_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_kr_ADVANCED_A.json',
      B: 'data/sentences_kr_ADVANCED_B.json',
      C: 'data/sentences_kr_ADVANCED_C.json',
    },
  },
  Russian: {
    BEGINNER: {
      A: 'data/sentences_rs_BEGINNER_A.json',
      B: 'data/sentences_rs_BEGINNER_B.json',
      C: 'data/sentences_rs_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_rs_INTERMEDIATE_A.json',
      B: 'data/sentences_rs_INTERMEDIATE_B.json',
      C: 'data/sentences_rs_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_rs_ADVANCED_A.json',
      B: 'data/sentences_rs_ADVANCED_B.json',
      C: 'data/sentences_rs_ADVANCED_C.json',
    },
  },
  Lithuanian: {
    BEGINNER: {
      A: 'data/sentences_lt_BEGINNER_A.json',
      B: 'data/sentences_lt_BEGINNER_B.json',
      C: 'data/sentences_lt_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_lt_INTERMEDIATE_A.json',
      B: 'data/sentences_lt_INTERMEDIATE_B.json',
      C: 'data/sentences_lt_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_lt_ADVANCED_A.json',
      B: 'data/sentences_lt_ADVANCED_B.json',
      C: 'data/sentences_lt_ADVANCED_C.json',
    },
  },
  German: {
    BEGINNER: {
      A: 'data/sentences_gr_BEGINNER_A.json',
      B: 'data/sentences_gr_BEGINNER_B.json',
      C: 'data/sentences_gr_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_gr_INTERMEDIATE_A.json',
      B: 'data/sentences_gr_INTERMEDIATE_B.json',
      C: 'data/sentences_gr_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_gr_ADVANCED_A.json',
      B: 'data/sentences_gr_ADVANCED_B.json',
      C: 'data/sentences_gr_ADVANCED_C.json',
    },
  },
  Japanese: {
    BEGINNER: {
      A: 'data/sentences_jp_BEGINNER_A.json',
      B: 'data/sentences_jp_BEGINNER_B.json',
      C: 'data/sentences_jp_BEGINNER_C.json',
    },
    INTERMEDIATE: {
      A: 'data/sentences_jp_INTERMEDIATE_A.json',
      B: 'data/sentences_jp_INTERMEDIATE_B.json',
      C: 'data/sentences_jp_INTERMEDIATE_C.json',
    },
    ADVANCED: {
      A: 'data/sentences_jp_ADVANCED_A.json',
      B: 'data/sentences_jp_ADVANCED_B.json',
      C: 'data/sentences_jp_ADVANCED_C.json',
    },
  }
};

console.log(languageFiles);




export default languageFiles;
