import React, { useEffect, useState } from 'react';
import { MoreHorizontal, Globe, User, ArrowBigLeft, ArrowLeft } from 'lucide-react';
import { box_shadows, color_dict } from '../utils/colors';
import languageFlags from '../utils/languageFlags';
import Button from './Button';


function NavBar({
  currentLanguage,
  changeLanguage,
  title,
  changeStory,
  setPopup,
  setPopupState,
  level,
  user,
  isAuthenticated,
  isPremium,
  renderStory = true,
}) {


  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      }}
    >
      <div
        style={{
          backgroundColor: color_dict.darkerBackground,
          flexShrink: 0,
          height: '50px',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '7px 10px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <ArrowLeft
          style={{
            size: '80%',
            color: color_dict.ligherPapyrus,
            strokeWidth: '4px',
          }}
          onClick={() => {
            if (isAuthenticated)
              changeStory()
            else {
              setPopup(true);
              setPopupState('auth');
            }
          }}
        />


        {
          isAuthenticated ?
            (
              <div style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
                gap: '5px',

                color: color_dict.ligherPapyrus,
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'end',
                  gap: '0px'
                }}>
                  <div style={{
                    fontSize: '15px',
                    fontWeight: '600'
                  }}>{user.given_name}</div>

                  <div style={{
                    fontSize: '11px',
                    color: color_dict.papyrus
                  }}>{isPremium ? "Premium" : "Standard"} User</div>
                </div>
                <div
                  style={{
                    backgroundColor: color_dict.ligherPapyrus,
                    height: '100%',
                    aspectRatio: '1 / 1',
                    borderRadius: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setPopupState('profile_auth')
                    setPopup(true)
                  }}
                >
                  <img src={user.picture} style={{ height: '100%' }} />
                  {/* <User size="80%" color={color_dict.darkerBackground} /> */}
                </div>
              </div>
            )
            :
            (
              <Button style={{
                backgroundColor: color_dict.papyrus,
                color: color_dict.darkerFontColorWarm
              }}
                onClick={
                  () => {
                    setPopup(true);
                    setPopupState('auth');
                  }
                }
              >Sign-In</Button>
            )
        }

      </div>
      {
        (currentLanguage && currentLanguage.length > 0) && (

          <div
            style={{
              backgroundColor: color_dict.ligherPapyrus,
              padding: '10px 15px 10px 10px',
              borderBottomRightRadius: '20px',
              fontSize: '20px',
              fontWeight: '700',
              color: color_dict.darkerFontColorWarm,
              alignSelf: 'start',
              zIndex: 3,
              boxShadow: box_shadows.A3,

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'stretch',

              cursor: 'pointer',
              // gap:'0px',
            }}
            onClick={() => {
              if (isAuthenticated)
                changeStory()
              else {
                setPopup(true);
                setPopupState('auth');
              }
            }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '15px',
              color: color_dict.darkerBackground
            }}>

              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '5px',
              }}>
                <img src={"flag_icons/" + languageFlags[currentLanguage]} style={{
                  height: '20px',
                }} />
                {currentLanguage}
              </div>

              <div>
                {level}
              </div>
            </div>
            {title}


            {/* <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          color: color_dict.darkerBackground,
          fontSize: '13px',
        }}>
          Tap to change
        </div> */}

          </div>
        )
      }
    </div>
  );
}

export default NavBar;
