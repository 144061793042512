import React, { useEffect, useState, useRef } from 'react';
import { Howl } from 'howler';
import { KindeProvider, useKindeAuth } from "@kinde-oss/kinde-auth-react";

import { color_dict } from '../utils/colors';
import { BACKEND_URL } from '../utils/constants';
import NavBar from './NavBar';
import InteractiveSegment from './InteractiveSegment';
import PopupManager from './PopupManager';

function MainPage({ isLoading, isAuthenticated, user, login, register, logout, isPremium }) {
    
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    const [data, setData] = useState(null);
    const [title, setTitle] = useState("");
    const [level, setLevel] = useState("");
    const [currentLanguage, setCurrentLanguage] = useState('');

    const [translations, setTranslations] = useState({});
    const [reverseTranslations, setReverseTranslations] = useState({});

    const [selectedWordIndex, setSelectedWordIndex] = useState(0);
    const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
    const [thinking, setThinking] = useState(false);
    const [phonetic, setPhonetic] = useState(false);
    const [reverse, setReverse] = useState(false);
    const [hint, setHint] = useState(false);

    const [popup, setPopup] = useState(true);
    const [popupState, setPopupState] = useState('language_choice');

    const [currentTranslation, setCurrentTranslation] = useState('');

    const [retries, setRetries] = useState(0);
    const [progress, setProgress] = useState(0);  // Track progress

    const containerRef = useRef(null);

    const [shouldFocus, setShouldFocus] = useState(false);

    const good_guess_sound = new Howl({
        src: ['/sound_effect_1.mp3'],
        preload: true
    });

    const bad_guess_sound = new Howl({
        src: ['/sound_effect_4.mp3'],
        preload: true
    });

    useEffect(() => {
        const viewport = window.visualViewport;

        function handleResize() {
            setViewportHeight(viewport.height);
        }

        function handleScroll() {
            // Immediately scroll back to the top whenever a scroll happens
            window.scrollTo(0, 0);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 50);
        }

        viewport.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listeners on unmount
        return () => {
            viewport.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        console.log('use effect is loading: ', isLoading, user)
        if (isLoading) {
            setPopup(true);
            setPopupState('loading')
        }
        else {
            if (!isAuthenticated) {
                setPopupState('welcome');
            }
            else {
                setPopupState('language_choice');
            }

        }
    }, [isLoading, isAuthenticated, user]);

    // New useEffect for calculating progress
    useEffect(() => {
        if (!data || data.length === 0) return;

        // Calculate total words in the story
        const totalWords = data.reduce((sum, sentence) => sum + (sentence.words.length * 2), 0);

        // Calculate completed words
        // const completedWords = data.slice(0, currentSentenceIndex).reduce((sum, sentence) => sum + sentence.words.length, 0) + selectedWordIndex;
        const completedWords_reg = Object.values(translations).reduce(
            (count, sentence) => count + Object.keys(sentence).length,
            0
        );

        const completedWords_rev = Object.values(reverseTranslations).reduce(
            (count, sentence) => count + Object.keys(sentence).length,
            0
        );

        const completedWords = completedWords_reg + completedWords_rev;
        // Update progress
        const newProgress = (completedWords / totalWords) * 100;
        setProgress(newProgress);
    }, [data, currentSentenceIndex, selectedWordIndex]);


    const changeLanguage = () => {
        setPopupState("language_choice");
        setPopup(true);
    };

    const changeStory = () => {
        setPopupState("pick_story");
        setPopup(true);
    };

    const handleLanguageChange = (newLanguage) => {
        setCurrentLanguage(newLanguage);
        resetGame();
        setPopup(true);
        if (isAuthenticated)
            setPopupState('pick_story');
        else
            setPopupState('try_it_out_expl');
    };

    const handleStoryChange = async (storyID, storyTitle, storyLevel, close_popup = true) => {
        if (close_popup) {
            setPopup(true);
            setPopupState("loading");
        }

        try {
            const response = await fetch(`/data_new/${currentLanguage}/${storyID}.json`);
            const data = await response.json();

            setData(data);
        } catch {
            console.log('error loading data');
        }

        setTitle(storyTitle);
        setLevel(storyLevel);
        resetGame(close_popup);

    };

    const resetGame = (close_popup = true) => {
        if (close_popup)
            setPopup(false);

        setRetries(0);
        setTranslations({});
        setReverseTranslations({});
        setSelectedWordIndex(0);
        setCurrentSentenceIndex(0);
        setProgress(0);  // Reset progress when starting a new game
        setPhonetic(true);
    };

    const handleCorrectTranslation = (translation) => {
        if (!reverse) {
            setTranslations((prevTranslations) => ({
                ...prevTranslations,
                [currentSentenceIndex]: {
                    ...prevTranslations[currentSentenceIndex],
                    [selectedWordIndex]: translation,
                },
            }));

        } else {
            setReverseTranslations((prevTranslations) => ({
                ...prevTranslations,
                [currentSentenceIndex]: {
                    ...prevTranslations[currentSentenceIndex],
                    [selectedWordIndex]: translation,
                },
            }));
        }

        if (selectedWordIndex + 1 < data[currentSentenceIndex].words.length) {
            setSelectedWordIndex((prevIndex) => prevIndex + 1);
        } else {
            if (reverse) {
                // setPopupState('sentence_check'); TODO: DECIDE ABOUT THIS
                // setPopup(true);

                goToNextSentence();
            } else {
                // if (currentSentenceIndex === 0) {
                setPopup(true);
                setPopupState('reverse_sentence_instruction');
                // }
                setReverse(true);
                setSelectedWordIndex(0);
            }
        }

        good_guess_sound.play();
        setTimeout(() => {
            good_guess_sound.stop();
        }, 2000);
    };

    const handleWrongTranslation = () => {
        // Wrong translation - reset the current sentence
        setPopup(true);
        setPopupState('incorrect');
        bad_guess_sound.play();
        setTimeout(() => {
            bad_guess_sound.stop();
        }, 2000);
    };

    const normalizeText = (text) => {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const checkTranslation = async (translation) => {
        if (!data) return;
        setCurrentTranslation(translation);
        const normalized_translation = normalizeText(translation);
        let memoMatch = false;

        if (reverse) {
            const normalized_words = [
                normalizeText(phonetic ? data[currentSentenceIndex].words[selectedWordIndex].phonetic : data[currentSentenceIndex].words[selectedWordIndex].original),
            ];

            memoMatch = normalized_words
                .map((t) => t.toLowerCase().replace(/[^a-z]/gi, ''))
                .includes(normalized_translation.toLowerCase().replace(/[^a-z]/gi, ''));
        } else {
            const normalized_words = data[currentSentenceIndex].words[selectedWordIndex].translations.map((item) => normalizeText(item));

            memoMatch = normalized_words
                .map((t) => t.toLowerCase().replace(/[^a-z]/gi, ''))
                .includes(normalized_translation.toLowerCase().replace(/[^a-z]/gi, ''));
        }

        if (memoMatch) {
            handleCorrectTranslation(translation);
        } else {
            handleWrongTranslation();
        }

        setThinking(false);
    };

    const resetCurrentSentence = () => {
        setRetries((r) => r + 1);

        if (reverse) {
            setReverseTranslations((prevTranslations) => ({
                ...prevTranslations,
                [currentSentenceIndex]: {},
            }));
            // setTranslations((prevTranslations) => ({
            //     ...prevTranslations,
            //     [currentSentenceIndex]: {},
            // }));
        } else {
            setTranslations((prevTranslations) => ({
                ...prevTranslations,
                [currentSentenceIndex]: {},
            }));
        }

        setSelectedWordIndex(0);
        // setReverse(false);
    };

    const goToNextSentence = () => {
        setReverse(false);

        if (currentSentenceIndex < data.length - 1) {
            setCurrentSentenceIndex((i) => i + 1);
            setSelectedWordIndex(0);
        } else {
            setCurrentSentenceIndex((i) => i + 1);
            setPopupState('finished');
            setPopup(true);
        }
    };

    return (
        <div
            ref={containerRef}
            style={{
                backgroundColor: color_dict.background,
                width: '100vw',
                height: viewportHeight,
                fontFamily: 'Plus Jakarta Sans',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                minWidth: 1,
                minHeight: 1,
                overflow: 'hidden',
                position: 'absolute',
                right: 0,
                top: 0,
            }}
        >

            <NavBar
                currentLanguage={currentLanguage}
                changeLanguage={changeLanguage}
                title={title}
                changeStory={changeStory}
                setPopup={setPopup}
                setPopupState={setPopupState}
                level={level}
                user={user}
                isAuthenticated={isAuthenticated}
                isPremium={isPremium}
            />

            <div
                style={{
                    flex: '1 0 0%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'stretch',
                    minHeight: 1,
                }}
            >
                <div style={{ position: 'relative', minHeight: 1 }}>
                    <InteractiveSegment
                        selectedWordIndex={selectedWordIndex}
                        data={data}
                        checkTranslation={checkTranslation}
                        thinking={thinking}
                        viewportHeight={viewportHeight}
                        translations={translations}
                        reverseTranslations={reverseTranslations}
                        currentSentenceIndex={currentSentenceIndex}
                        phonetic={phonetic}
                        switchPhonetic={() => setPhonetic((e) => !e)}
                        setHint={setHint}
                        popup={popup}
                        setPopup={setPopup}
                        setPopupState={setPopupState}
                        goToNextSentence={goToNextSentence}
                        reverse={reverse}
                        language={currentLanguage}
                        retries={retries}
                        progress={progress}
                        shouldFocus={shouldFocus}
                        setShouldFocus={setShouldFocus}
                    />
                </div>

                <PopupManager
                    setPopupState={setPopupState}
                    popupState={popupState}
                    setPopup={setPopup}
                    popup={popup}
                    setHint={setHint}
                    hint={hint}
                    viewportHeight={viewportHeight}
                    language={currentLanguage}
                    data={data}
                    currentSentenceIndex={currentSentenceIndex}
                    selectedWordIndex={selectedWordIndex}
                    resetCurrentSentence={resetCurrentSentence}
                    goToNextSentence={goToNextSentence}
                    phonetic={phonetic}
                    currentTranslation={currentTranslation}
                    handleLanguageChange={handleLanguageChange}
                    handleStoryChange={handleStoryChange}
                    attempts={retries}
                    setAttempts={setRetries}
                    resetGame={resetGame}
                    reverse={reverse}
                    setLevel={setLevel}
                    login={login}
                    register={register}
                    logout={logout}
                    user={user}
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                    isPremium={isPremium}
                />
            </div>
        </div>
    );
}

export default MainPage;
