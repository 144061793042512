import React, { useState, useEffect } from 'react';
import Word from './Word';
import Button from './Button';
import { color_dict } from '../utils/colors';
import { BACKEND_URL } from '../utils/constants';
import { ArrowRight, LoaderCircle, LoaderPinwheel } from 'lucide-react';
import LoadingWheel from './LoadingWheel';
import Popup from './Popup';

function IncorrectPopup({
    currentWord,
    selectedWordIndex,
    currentSentence,
    setPopup,
    hint,
    setHint,
    resetCurrentSentence,
    language,
    phonetic,
    currentTranslation,
    reverse
}) {
    const [message, setMessage] = useState('');

    // useEffect(() => {
    //     if (message.length > 0) return;

    //     const fetchData = async () => {
    //         const requestData = {
    //             words: currentSentence.map((item) => item.original),
    //             active_word_index: selectedWordIndex,
    //             language: language,
    //         };

    //         const response = await fetch(`${BACKEND_URL}/explain_word`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(requestData),
    //         });
    //         const result = await response.json();
    //         setMessage((pv) => (pv.length ? pv : result.explanation));
    //     };
    //     fetchData();
    // }, [
    //     message.length,
    //     currentSentence,
    //     selectedWordIndex,
    //     language,
    //     currentTranslation,
    //     currentWord,
    // ]);

    return (
        <Popup>
            <div style={{
                backgroundColor: color_dict.darkerBackground,
                position: 'absolute',
                top: 0,
                left: 0,

                width: '100%',
                height: '50px',

                zIndex: 1,
            }}>

            </div>

            <div
                style={{
                    fontSize: '30px',
                    color: color_dict.ligherPapyrus,
                    zIndex: 2,
                }}
            >
                {hint ? 'Explanation' : 'Incorrect'}
            </div>

            <div>
                <div
                    style={{
                        color: color_dict.darkerFontColor,
                        marginBottom: '5px',
                    }}
                >
                    <div style={{ marginBottom: '5px' }}>{hint ? "The word:" : "You guessed"}</div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Word value={reverse ? currentWord.translations[0] : phonetic ? currentWord.phonetic : currentWord.original} />
                        {!hint && (<>
                            <ArrowRight />
                            <Word value={currentTranslation} />
                        </>)}
                    </div>
                </div>

                <div>
                    <div style={{ marginBottom: '5px' }}>Correct Translations</div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: '5px',
                        }}
                    >
                        {
                            (reverse ? [(phonetic ? currentWord.phonetic : currentWord.original),] : currentWord.translations).map((item, index) => (
                                <Word key={index} value={item} translated={true} />
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* <div>
                <div style={{ marginBottom: '5px' }}>Explanation</div>
                <div
                    style={{
                        backgroundColor: color_dict.ligherPapyrus,
                        padding: '10px',
                        boxSizing: 'border-box',
                        height: '100px',
                        overflowY: 'auto',
                        borderRadius: '10px',
                        color: color_dict.fontColor,
                        marginBottom: '10px',
                        fontWeight: '400',

                        position: 'relative'
                    }}
                >
                    {message.length ? message : (
                        <LoadingWheel />
                    )}
                </div>
            </div> */}

            
            <div style={{
                fontSize: '15px',
                color: color_dict.darkerFontColorWarm,
            }}>
                You will now retry translating the sentence.
            </div>

            <Button
                onClick={() => {
                    setPopup(false);
                    setHint(false);
                    resetCurrentSentence();
                }}
            >
                Retry
            </Button>
        </Popup>
    );
}

export default IncorrectPopup;
