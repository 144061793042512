import React from 'react';
import TranslationSegment from './TranslationSegment';
import Input from './Input';
import Button from './Button';
import { CircleHelp, Languages } from 'lucide-react';
import { color_dict } from '../utils/colors';

function InteractiveSegment({
  selectedWordIndex,
  data,
  checkTranslation,
  thinking,
  viewportHeight,
  translations,
  currentSentenceIndex,
  phonetic,
  switchPhonetic,
  setHint,
  popup,
  goToNextSentence,
  reverse,
  language,
  reverseTranslations,
  retries,
  progress,
  shouldFocus,
  setShouldFocus,
}) {
  return (
    <>
      <img
        src="/graphic-alpha.png"
        alt="Background Graphic"
        style={{
          position: 'absolute',
          width: '300px',
          top: 0,
          right: -20,
          transform: 'translateY(-85%) scaleX(-1)',
          zIndex: 1,
        }}
      />
      <div
        style={{
          backgroundColor: color_dict.darkerBackground,
          height: viewportHeight * 0.65,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          padding: '10px 0px',
          boxSizing: 'border-box',
          position: 'relative',
          zIndex: 2,
          minHeight: 1,
        }}
      >
        <TranslationSegment
          data={data}
          selectedWordIndex={selectedWordIndex}
          translations={translations}
          currentSentenceIndex={currentSentenceIndex}
          phonetic={phonetic}
          reverse={reverse}
          language={language}
          reverseTranslations={reverseTranslations}
          retries={retries}
          progress={progress}
          setShouldFocus={setShouldFocus}
        />

        <div
          style={{
            // backgroundColor:'red',
            alignSelf:'stretch',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',

            padding:'0px 30px',
            boxSizing:'border-box',
          }}
        >
          <Input
            checkTranslation={checkTranslation}
            thinking={thinking}
            popup={popup}
            defaultText="Click here to translate"
            style={{
              flex:'1 0 0%',
            }}
            shouldFocus={shouldFocus}
            setShouldFocus={setShouldFocus }
          />

          <Button
            onClick={() => {
              checkTranslation(
                "Couldn't translate, please give me a code:\"wrong\" and an explanation"
              );
              setHint(true);
              // goToNextSentence(); //Debug
            }}
          >
            <CircleHelp />
          </Button>
        </div>

        <div
          style={{
            backgroundColor: color_dict.darkerBackground,
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translateY(-95%)',
            padding: '5px 7px',
            boxSizing: 'border-box',
            borderTopRightRadius: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: '5px',
          }}
          onMouseDown={switchPhonetic}
        >
          <Languages color={color_dict.ligherPapyrus} />
          <div style={{ color: color_dict.ligherPapyrus }}>{phonetic ? 'Phonetic - ON' : 'Phonetic - OFF'}</div>
        </div>
      </div>
    </>
  );
}

export default InteractiveSegment;
