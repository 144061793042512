import React, { useRef, useState, useEffect } from 'react';
import { ArrowRightCircle, Pointer, ZapIcon } from 'lucide-react';
import { color_dict } from '../utils/colors';
import LoadingWheel from './LoadingWheel';

function ClickHereIndicator({ style }) {
  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      ...style
    }}>
      <Pointer color="red" size="30px" style={{
        animation: 'pulseRotate 1.5s ease-in-out infinite',
      }}>
        <style>{`
@keyframes pulseRotate {
  0% {
      transform: scale(1) rotate(-10deg);
  }
  50% {
      transform: scale(1.2) rotate(10deg);
  }
  100% {
      transform: scale(1) rotate(-10deg);
  }
}
`}</style>
      </Pointer>
    </div>
  );
}

function Input({ thinking, checkTranslation, popup, style, defaultText, shouldFocus = false, setShouldFocus = null }) {
  const editableDivRef = useRef(null);
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [firstFocus, setFirstFocus] = useState(false);

  const newLine = () => {
    if (editableDivRef.current && !thinking && !popup && value.length > 0) {
      const translation = editableDivRef.current.textContent;
      editableDivRef.current.textContent = '';
      checkTranslation(translation);
    }
  };

  const handleKeyDown = (e) => {
    if (thinking || popup) e.preventDefault();
    if (e.key === 'Enter') {
      e.preventDefault();
      newLine();
    }
  };

  useEffect(() => {
    if (editableDivRef.current) {
      editableDivRef.current.blur();
    }
  }, [popup]);

  useEffect(() => {
    if (shouldFocus) {
      editableDivRef.current.focus();
      setShouldFocus(false);
    }

  }, [shouldFocus])

  return (
    <div
      style={{
        opacity: thinking ? 0.5 : 1,
        backgroundColor: color_dict.ligherPapyrus,
        width: '200px',
        height: '35px',
        fontSize: '20px',
        color: color_dict.darkerFontColor,
        padding: '5px 10px',
        boxSizing: 'border-box',
        borderRadius: '20px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '5px',
        transition: '0.2s all',
        position: 'relative',
        ...style,
      }}
    >
      {
        (!firstFocus) && (
          <ClickHereIndicator style={{
            zIndex: 6,
            opacity: 1,
            left: '70%',
            top: '90%',
          }}
            onClick={() => {
              if (editableDivRef.current)
                editableDivRef.current.focus()
            }}
          />
        )
      }


      {thinking && <LoadingWheel />}

      {value.length <= 1 && !isFocused && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '45%',
            transform: 'translateY(-50%) translateX(-50%)',
            pointerEvents: 'none',
            opacity: 0.5,
            color: color_dict.darkerFontColorWarm,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

            fontSize:'17px',
          }}
        >
          {defaultText}
        </div>
      )}
      <div
        ref={editableDivRef}
        contentEditable={!thinking}
        onKeyDown={handleKeyDown}
        style={{ flex: '1 0 0%' }}
        onInput={(e) => {
          setValue(e.target.innerText)
          console.log(value.length);
        }}
        onFocus={() => {
          setIsFocused(true);
          setFirstFocus(true);
        }}
        onBlur={() => setIsFocused(false)}
      />
      <ArrowRightCircle
        color={color_dict.darkerFontColor}
        onMouseDown={(e) => {
          e.preventDefault(); // Prevents the focus from shifting
          newLine();
        }}
        opacity={value.length > 0 ? 1 : 0.5}
      />
    </div>
  );
}

export default Input;
