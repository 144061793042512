import { useState } from "react";

import { CheckCheck } from "lucide-react";
import { color_dict, box_shadows } from "../utils/colors";
import Button from "./Button";

function LanguageCard({ flag, language, selectedLanguage, setSelectedLanguage }) {
    const selected = selectedLanguage === language
    
    return (
        <div style={{
            backgroundColor: selected ? color_dict.ligherPapyrus2 : color_dict.ligherPapyrus,
            padding: '5px 15px',
            boxSizing: 'border-box',

            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',

            height: '45px',
            boxShadow: box_shadows.A3,

            color: selected ? color_dict.darkerBackground : color_dict.darkerFontColorWarm,

            transform: selected ? 'scale(103%)' : '',
            transition: '0.2s all',

            position: 'relative',

            cursor: 'pointer',
        }}
        onClick={()=>setSelectedLanguage(language)}
        >
            <CheckCheck color="green" style={{
                position: 'absolute',
                left: '10px',
                opacity: selected ? 1 : 0,
                transition: '0.2s all'
            }} />
            <img src={"flag_icons/" + flag} style={{
                height: '100%',
            }} />

            <div style={{
                fontSize: '20px',
                fontWeight: '900',

            }}>{language}</div>
        </div>
    )
}


function LanguageChoice({handleLanguageChange}) {
    const [selectedLanguage, setSelectedLanguage] = useState("");


    return (
        <div
            style={{
                backgroundColor: color_dict.darkerBackground,
                width: '300px',
                height: '500px',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                gap: '12px',
                padding: '12px 15px',
                boxSizing: 'border-box',
                color: color_dict.darkerFontColor,
                fontWeight: '700',
                overflow: 'hidden',
            }}
        >
            <div style={{
                textAlign: 'center',
                fontSize: '20px',
                fontWeight: '300',
                color: color_dict.ligherPapyrus,
            }}>
                What <span style={{ fontWeight: '900' }}>Language</span> Do You Want to <span style={{ fontWeight: '900' }}>Learn</span>?
            </div>

            <div style={{
                backgroundColor: color_dict.papyrus,
                flex: '1 0 0%',

                borderRadius: '10px',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                gap: '15px',

                padding: '10px',
                boxSizing: 'border-box',

                overflowY: 'auto',
            }}>
                <LanguageCard flag={"es.png"} language={"Spanish"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"de.png"} language={"German"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"it.png"} language={"Italian"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"fr.png"} language={"French"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"kr.png"} language={"Korean"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"cn.png"} language={"Chinese"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"jp.png"} language={"Japanese"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"ru.png"} language={"Russian"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                <LanguageCard flag={"lt.png"} language={"Lithuanian"} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />

            </div>

            <Button active={selectedLanguage.length} style={{
                fontSize: '20px',
            }} onClick={()=>handleLanguageChange(selectedLanguage)}>Done</Button>
        </div>
    )
}

export default LanguageChoice;