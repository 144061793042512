import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import Sentence from './Sentence';
import { color_dict } from '../utils/colors';

function TranslationSegment({
  data,
  selectedWordIndex,
  translations,
  currentSentenceIndex,
  phonetic,
  language,
  reverse,
  reverseTranslations,
  retries,
  progress,
  setShouldFocus
}) {
  const sentenceRefs = useRef([]);
  const containerRef = useRef(null);

  // const [sentenceRefs2, setSentenceRefs2] = useState([]);


  const [sentencesOffsetHeight, setSentencesOffsetHeight] = useState(0);
  // Scroll to the current sentence when currentSentenceIndex changes
  useEffect(() => {
    if (!containerRef.current || sentenceRefs.current.length == 0) return;
    const subsetRefs = sentenceRefs.current.slice(0, currentSentenceIndex);
    const totalHeight = subsetRefs.reduce((sum, ref) => {
      console.log(ref);
      const { height } = ref.getBoundingClientRect();
      console.log('ref height: ', height)
      return sum + height;
    }, 0);

    console.log('totalHeight: ', totalHeight)
    setSentencesOffsetHeight(totalHeight);

  }, [currentSentenceIndex]);

  useEffect(()=>{
    console.log("reverseTranslations:", reverseTranslations)

  }, [reverseTranslations])

  return (
    <div
      ref={containerRef}
      className="translation-segment"
      style={{
        backgroundColor: color_dict.papyrus,
        width: '350px',
        flex: '1 0 0%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        // gap: '15px',
        borderRadius: '10px',
        alignSelf: 'center',
        fontSize: '12px',
        maxHeight: '100%',
        overflow:'hidden'
      }}
    >
      <div style={{
        backgroundColor:color_dict.mediumBackground,
        flexShrink:0,
        height:'25px',
        // width:'80%',

        // borderBottomLeftRadius:'20px',
        // borderBottomRightRadius:'20px',

        borderBottom:`0.5px solid ${color_dict.darkerBackground}`,

        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',

        padding:'0px 40px',
        boxSizing:'border-box',

        color:color_dict.ligherPapyrus,
        fontWeight:'800',
        zIndex:3,

        fontSize:'15px',

      }}>
        <div><span style={{color:color_dict.papyrus, fontWeight:'800'}}>Attempts:</span> {retries}</div>
        <div><span style={{color:color_dict.papyrus, fontWeight:'800'}}>Progress:</span> {Math.floor(progress)}%</div>
      </div>
      <div style={{
        // backgroundColor: 'red',
        flex: '1 0 0%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        // gap: '15px',
        transform: `translateY(${-sentencesOffsetHeight}px)`,
        // translateY:-sentencesOffsetHeight,
        padding: '5px',
        boxSizing: 'border-box',
        transition: '0.2s all',
      }}>
        {data &&
          data.map((sentence, sentenceIndex) => (
            <div
              style={{
                backgroundColor:reverse && (currentSentenceIndex === sentenceIndex) ? color_dict.mediumBackground : 'transparent',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'stretch',

                gap: '15px',
                padding: '0px 5px',
                boxSizing:'border-box',
                borderRadius:'10px',

                transition: '0.2s all',
              }}
              key={sentenceIndex}
              ref={(el) => (sentenceRefs.current[sentenceIndex] = el)} // Immediately invoked function to capture `sentenceIndex`
            >
              <Sentence
                sentence={sentence}
                selectedWordIndex={selectedWordIndex}
                translations={translations}
                reverseTranslations={reverseTranslations}
                sentenceIndex={sentenceIndex}
                currentSentenceIndex={currentSentenceIndex}
                phonetic={phonetic}
                reverse={reverse && (currentSentenceIndex === sentenceIndex) }
                language={language}
                setShouldFocus={setShouldFocus}
              />
              <div
                style={{
                  backgroundColor: color_dict.background,
                  flex: '0 0 0.5px',
                  width: '100%',
                  alignSelf: 'center',
                }}
              ></div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default TranslationSegment;
