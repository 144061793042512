import Popup from "./Popup";
import Button from "./Button";
import { box_shadows, color_dict } from "../utils/colors";
import Word from "./Word";

function Instruction({ title, language, close, sentence }) {
    return (
        <Popup>

            <div style={{
                fontSize: '25px',

            }}>{title}</div>

            <div style={{
                backgroundColor: color_dict.ligherPapyrus,
                padding: '5px',
                boxSizing: 'border-box',
                borderRadius: '10px',
                boxShadow: box_shadows.A3,

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '5px',
            }}>
                {
                    sentence.words.map((item, index) => (

                        <Word
                            key={index}
                            value={item.translations[0]}
                            active={false}
                            translated={true}
                            // translation={translations[sentenceIndex] && translations[sentenceIndex][index]}
                            reverse={false}
                        />
                    ))
                }
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '5px',
            }}>
                <div>Means:</div>
                <div style={{
                    backgroundColor:color_dict.lightBlue,
                    color: color_dict.fontColor,

                    padding:'5px',
                    boxSizing:'border-box',

                    borderRadius:'10px',
                    boxShadow:box_shadows.A3,
                }}>{sentence.translation}</div>
            </div>


            <div style={{
                fontSize: '15px',
                color: color_dict.darkerFontColorWarm,
            }}>
                Now, you will have to translate this sentence in reverse! from English to {language}.
            </div>

            <Button onClick={close}>Gotcha</Button>
        </Popup>
    )
}

export default Instruction;
